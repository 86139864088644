import {
	ActivityType,
	CoverageLimitType,
	SubscriberCivility,
} from "@prisma/client";
import { addDays, isAfter, isBefore } from "date-fns";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import { z } from "zod";

export const turnoverSchema = z.coerce
	.number({
		invalid_type_error: "Veuillez saisir votre chiffre d’affaires.",
		required_error: "Le chiffre d'affaires doit être renseigné.",
	})
	.min(0, "Veuillez saisir votre chiffre d’affaires.");

export const worldWideTurnoverSchema = z.coerce
	.number({
		invalid_type_error: "Veuillez saisir votre chiffre d’affaires USA/CA.",
	})
	.optional()
	.nullable();

export const emailSchema = z
	.string({
		required_error: "Veuillez saisir une adresse e-mail.",
		invalid_type_error: "L'adresse e-mail doit être valide.",
	})
	.nonempty("Veuillez saisir une adresse e-mail.")
	.email("L'adresse e-mail doit être valide.");

export const sirenOrSiretSchema = z.coerce
	.string({
		required_error: "Veuillez saisir le numéro SIREN ou SIRET.",
		invalid_type_error: "Veuillez saisir le numéro SIREN ou SIRET.",
	})
	.regex(
		/^\d+$/,
		"Le numéro SIREN ou SIRET doit contenir uniquement des chiffres.",
	)
	.refine((val) => val.length === 9 || val.length === 14, {
		message:
			"Le numéro SIREN doit contenir 9 chiffres ou le numéro SIRET doit contenir 14 chiffres.",
	});

export const phoneNumberSchema = z
	.string({
		required_error: "Veuillez saisir le numéro de téléphone.",
		invalid_type_error: "Veuillez saisir le numéro de téléphone.",
	})
	.refine((value) => value && isValidPhoneNumber(value), {
		message: "Veuillez saisir un numéro de téléphone portable valide.",
	});

export const companyNameSchema = z
	.string({
		required_error: "Veuillez saisir le nom de la société.",
		invalid_type_error: "Veuillez saisir le nom de la société.",
	})
	.min(1, "Veuillez saisir le nom de la société.");

export const addressSchema = z
	.string({
		required_error: "Veuillez saisir l'addresse.",
		invalid_type_error: "Veuillez saisir l'addresse.",
	})
	.min(1, "Veuillez saisir l'adresse de la société.");

export const firstNameSchema = z
	.string({
		required_error: "Veuillez saisir votre nom.",
		invalid_type_error: "Veuillez saisir votre nom.",
	})
	.min(1, "Veuillez saisir votre nom.")
	.regex(/^[a-zA-ZÀ-ÖØ-öø-ÿ]+$/, "Le nom ne doit contenir que des lettres.");

export const lastNameSchema = z
	.string({
		required_error: "Veuillez saisir votre prénom.",
		invalid_type_error: "Veuillez saisir votre prénom.",
	})
	.min(1, "Veuillez saisir votre prénom.")
	.regex(/^[a-zA-ZÀ-ÖØ-öø-ÿ]+$/, "Le prénom ne doit contenir que des lettres.");

export const citySchema = z
	.string({
		required_error: "Veuillez saisir votre ville.",
		invalid_type_error: "Veuillez saisir votre ville.",
	})
	.min(1, "Veuillez saisir votre ville.");

export const countrySchema = z
	.string({
		required_error: "Veuillez saisir votre pays.",
		invalid_type_error: "Veuillez saisir votre pays.",
	})
	.min(2, "Veuillez saisir votre pays.")
	.default("FR");

export const birthdaySchema = z.coerce
	.date({
		required_error: "Veuillez saisir votre date de naissance.",
		invalid_type_error: "Veuillez saisir une date valide.",
	})
	.refine((date) => date <= new Date(), {
		message: "La date de naissance ne peut pas être dans le futur.",
	})
	.refine(
		(date) => {
			const today = new Date();
			const age = today.getFullYear() - date.getFullYear();
			return age >= 18;
		},
		{
			message: "Veuillez saisir votre date de naissance.",
		},
	);

export const postalCodeSchema = z
	.string({
		required_error: "Veuillez saisir un code postal.",
		invalid_type_error: "Veuillez saisir un code postal valide.",
	})
	.nonempty("Veuillez saisir un code postal.")
	.regex(/^\d+$/, "Le code postal doit contenir uniquement des chiffres.")
	.min(0, "Le code postal doit contenir au moins 3 caractères.")
	.max(5, "Le code postal doit contenir au plus de 5 caractères.");

export const civilitySchema = z.nativeEnum(SubscriberCivility, {
	required_error: "Veuillez sélectionner la civilité.",
	invalid_type_error: "Veuillez sélectionner la civilité.",
});
export type CivilityDto = z.infer<typeof civilitySchema>;

export const effectiveDateSchema = z.coerce
	.date({
		errorMap: (error) => {
			switch (error.message) {
				case "Invalid date":
					return {
						message: "Veuillez saisir une date d'effet du contrat valide.",
					};
				case "Invalid type":
					return {
						message: "Veuillez saisir une date d'effet du contrat valide.",
					};
				default:
					return {
						message: "Veuillez saisir une date d'effet du contrat valide.",
					};
			}
		},
	})
	.refine((date) => isAfter(date, new Date()), {
		message: "Veuillez saisir une date d'effet dans le futur.",
	})
	.refine(
		(date) => {
			const threeMonthsFromNow = addDays(new Date(), 90);
			return isBefore(date, threeMonthsFromNow);
		},
		{
			message:
				"La date d'effet ne peut pas dépasser 3 mois à partir d'aujourd'hui.",
		},
	);

export const coverageLimitSchema = z.nativeEnum(CoverageLimitType, {
	required_error: "Veuillez sélectionner la limite de garantie.",
	invalid_type_error: "Veuillez sélectionner la limite de garantie.",
});
export type CoverageLimitDto = z.infer<typeof coverageLimitSchema>;
export const declarationConfirmationSchema = z
	.boolean({
		required_error: "Veuillez confirmer les déclarations.",
		invalid_type_error: "Veuillez confirmer les déclarations.",
	})
	.refine((val) => val, {
		message: "Veuillez confirmer les déclarations.",
	});

export const partnershipIdSchema = z.string({
	required_error: "Veuillez saisir l'ID du partenariat.",
	invalid_type_error: "Veuillez saisir l'ID du partenariat.",
});

export const wordWideTurnoverSchema = z.coerce
	.number({
		invalid_type_error: "Veuillez saisir votre chiffre d’affaires USA/CA.",
	})
	.optional();

export const principalActivityTypeSchema = z.nativeEnum(ActivityType, {
	required_error: "Veuillez sélectionner le type d'activité principal.",
	invalid_type_error: "Veuillez sélectionner le type d'activité principal.",
});
export const complementaryActivitiesTypesSchema = z.array(
	z.nativeEnum(ActivityType),
);
