import CivilityInput from "@/components/inputs/CivilityInput.tsx";
import { PhoneInput } from "@/components/inputs/PhoneInput.tsx";
import { TextInput } from "@/components/inputs/TextInput.tsx";

import type { CompanyDto, LegalRepresentiveDto } from "@repos/rcp-dtos";

export const LegalRepInfoInputSection = () => {
	return (
		<div className="flex w-full flex-col justify-evenly gap-6">
			<h1 className="text-3xl font-bold text-rcp-secondary">
				Informations du représentant légal
			</h1>
			<TextInput<LegalRepresentiveDto> label="Nom" name="lastName" required />
			<TextInput<LegalRepresentiveDto>
				label="Prénom"
				name="firstName"
				required
			/>
			<CivilityInput<LegalRepresentiveDto>
				name="civility"
				label="Civilité"
				required
			/>
			<TextInput<LegalRepresentiveDto> label="E-mail" name="email" required />
			<PhoneInput<CompanyDto> label="Téléphone" name="phoneNumber" required />
		</div>
	);
};
