import type { BrokerRoute } from "@/pages/form/routes";
import { brokerRoutes } from "@/pages/form/routes";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { useNavigate } from "react-router-dom";

type StateType = {
	[key: string]: string | number | boolean;
};
export const useCustomFormNavigate = () => {
	const navigate = useNavigate();
	const setCurrentStep = formStore((state) => state.setCurrentStep);

	return (route: keyof typeof BrokerRoute, state?: StateType) => {
		navigate("/formulaire/" + brokerRoutes[route].path, {
			state: { ...state, isSafe: true },
		});
		window.scroll(0, 0);
		setCurrentStep(brokerRoutes[route].step);
	};
};
