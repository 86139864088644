import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import DatePicker from "react-date-picker";
import { useFormContext } from "react-hook-form";

import "./styles.css";
import "react-calendar/dist/Calendar.css";

interface DateInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	placeholder?: string;
	required?: boolean;
	minDate?: Date;
	maxDate?: Date;
}

export function DateInput<T extends FieldValues>({
	label,
	name,
	required,
	minDate,
	maxDate,
}: DateInputProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			name={name}
			control={control}
			render={({ field }) => (
				<FormItem className="flex w-full flex-col">
					<FormLabel className="text-2xl font-medium text-primary">
						{label} {required && <span className="text-red-500">*</span>}
					</FormLabel>
					<DatePicker
						className="w-full rounded-2xl bg-white p-5  font-normal text-black "
						value={field.value}
						onChange={field.onChange}
						minDate={minDate}
						maxDate={maxDate}
					/>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
