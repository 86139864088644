import { useState } from "react";
import { TextInput } from "@/components/inputs/TextInput.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomNavigateWithSubID } from "@/hooks/useCustomNavigateWithSubID.ts";
import { useRedirectBasedOnPaymentStatus } from "@/hooks/useRedirectBasedOnPaymentStatus.ts";
import { useRedirectBasedOnSignatureStatus } from "@/hooks/useRedirectBasedOnSignatureStatus.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { usePaymentStore } from "@/stores/usePaymentStore.ts";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";

import type { BankInformationIBANRequestDto } from "@repos/rcp-dtos";
import { bankInformationIBANRequestSchema } from "@repos/rcp-dtos";

export const PaymentWithSEPAPage = () => {
	useRedirectBasedOnSignatureStatus({});
	useRedirectBasedOnPaymentStatus({
		refetchInterval: 2000,
	});
	const navigate = useCustomNavigateWithSubID({});
	const subscriptionId = useSubscriptionIdFromQueryParam();
	const [authorizationChecked, setAuthorizationChecked] = useState(false);
	const periodicity = usePaymentStore((state) => state.periodicity);
	if (!periodicity) {
		navigate("PaymentMethod");
	}
	const ibanPaymentMutation = trpc.payment.paymentIBAN.useMutation({
		onSuccess: () => {
			navigate("Signature");
		},
		onError: () => {
			toast({
				title: "Erreur",
				description:
					"Une erreur s'est produite lors du paiement. Veuillez vérifier vos informations et réessayer.",
				variant: "destructive",
			});
		},
	});
	const handleReturn = () => {
		// Return to previous page
		navigate("PaymentMethod");
	};
	const methods = useForm<BankInformationIBANRequestDto>({
		resolver: zodResolver(bankInformationIBANRequestSchema),
		defaultValues: {
			periodicity,
		},
	});

	const onSubmit = (data: BankInformationIBANRequestDto) => {
		ibanPaymentMutation.mutate({
			bankInformation: data,
			subscriptionId,
		});
	};
	return (
		<FormProvider {...methods}>
			<form
				className="flex h-full w-full max-w-[600px] flex-col items-center gap-6 px-6 lg:mt-20 lg:justify-center"
				onSubmit={methods.handleSubmit(onSubmit)}
			>
				<h1 className="text-2xl font-bold text-primary">
					Paiement par mandat SEPA
				</h1>
				<TextInput
					name={"bank_name"}
					label="Nom de la banque *"
					control={methods.control}
				/>
				<TextInput
					name={"account_holder"}
					label="Titulaire du compte *"
					control={methods.control}
				/>
				<TextInput name={"bic"} label="BIC *" control={methods.control} />
				<TextInput name={"iban"} label="IBAN" control={methods.control} />
				<div className="flex w-full items-center gap-4">
					<Checkbox
						id="terms"
						onCheckedChange={() => {
							setAuthorizationChecked(!authorizationChecked);
						}}
					/>
					<label
						htmlFor="terms"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						En cochant cette case vous autorisez “ AS Solutions” à envoyer des
						instructions à votre banque pour débiter votre compte du montant des
						échéances de vos garanties d’assurance.
					</label>
				</div>
				<div className="mb-16 flex w-full items-center justify-between">
					<Button
						variant="outline"
						className="rounded-xl px-6 py-3"
						onClick={handleReturn}
					>
						Retour
					</Button>
					<Button
						type="submit"
						className="rounded-xl px-6 py-3 "
						disabled={!authorizationChecked || ibanPaymentMutation.isLoading}
					>
						{ibanPaymentMutation.isLoading && (
							<Loader2 className="mr-2 h-4 w-4 animate-spin" />
						)}
						Confirmer
					</Button>
				</div>
			</form>
		</FormProvider>
	);
};
