import { z } from "zod";

import {
	declarationConfirmationSchema,
	partnershipIdSchema,
} from "../primitives";
import { FeesInputSchema } from "./pricing.dto";
import {
	BasicGuaranteeInputSchema,
	CoverageSelectionSchema,
	OptionalGuaranteesInputSelectionSchema,
	OptionalGuaranteesSelectionSchema,
	SubscriberSchema,
} from "./subscription.dto";

export const SubscriptionInputRouterSchema = z.object({
	coverageSelection: CoverageSelectionSchema,
	basicGuarantee: BasicGuaranteeInputSchema,
	optionalGuaranteesInputSelection: OptionalGuaranteesInputSelectionSchema,
	subscriber: SubscriberSchema,
	fees: FeesInputSchema,
	hasConfirmedDeclarations: declarationConfirmationSchema,
	partnershipId: partnershipIdSchema,
});

export const SubscriptionSchema = z.object({
	coverageSelection: CoverageSelectionSchema,
	basicGuarantee: BasicGuaranteeInputSchema,
	optionalGuaranteesInputSelection: OptionalGuaranteesInputSelectionSchema,
	optionalGuaranteesSelection: OptionalGuaranteesSelectionSchema,
	subscriber: SubscriberSchema,
	fees: FeesInputSchema,
	hasConfirmedDeclarations: declarationConfirmationSchema,
	partnershipId: partnershipIdSchema,
});

export type SubscriptionDto = z.infer<typeof SubscriptionSchema>;
