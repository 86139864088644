import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select.tsx";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";

export interface SelectOptionType {
	label: string;
	value: string;
}

interface SelectInputProps<T extends FieldValues, U extends SelectOptionType>
	extends UseControllerProps<T> {
	label: string;
	options: U[];
	required?: boolean;
}

export function SelectInput<T extends FieldValues, U extends SelectOptionType>({
	label,
	name,
	required,
	options,
	disabled,
}: SelectInputProps<T, U>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => {
				return (
					<div className="border-b border-b-gray-400 py-4">
						<FormItem className="flex w-full items-center justify-between">
							<FormLabel
								className={cn("text-xl font-bold", {
									"text-black/40": disabled,
								})}
							>
								{label}
								{required && <span className="text-red-500">*</span>}
							</FormLabel>
							<Select defaultValue={field.value} disabled={disabled}>
								<FormControl>
									<SelectTrigger className="w-4/12 rounded-2xl border-none bg-white p-6 text-xl font-medium">
										<SelectValue />
									</SelectTrigger>
								</FormControl>
								<SelectContent>
									{options.map((option) => (
										<SelectItem
											className="text-black"
											key={option.value}
											value={option.value.toString()}
										>
											{option.label}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</FormItem>
						<FormMessage />
					</div>
				);
			}}
		/>
	);
}
