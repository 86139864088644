import { useCallback } from "react";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { usePaymentStore } from "@/stores/usePaymentStore.ts";

export const useResetStores = () => {
	const resetStore = usePaymentStore((state) => state.reset);
	const resetFormStore = formStore((state) => state.reset);

	//Not reseting authStore because the partnership Id comes from geoprod, and we need to keep it
	return useCallback(() => {
		resetStore();
		resetFormStore();
	}, [resetStore, resetFormStore]);
};
