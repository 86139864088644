import logo from "@/assets/logo.png";
import { Toaster } from "@/components/ui/toaster.tsx";
import { Outlet } from "react-router-dom";

const Layout = () => {
	return (
		<div className="h-screen">
			<div className="z-50 flex w-full flex-col items-center bg-background px-10 py-5 lg:fixed lg:left-0 lg:top-0 lg:block">
				<img
					src={logo}
					alt="Logo"
					className=" w-32 lg:fixed lg:right-10 lg:top-0 lg:z-10"
				/>
				<h1 className="text-center text-3xl font-bold text-[#f70004] lg:text-start ">
					Assurance RC Pro by As Solutions
				</h1>
			</div>
			<main className="flex h-full justify-center py-0 lg:py-9">
				<Outlet />
			</main>
			<Toaster />
		</div>
	);
};

export default Layout;
