import { ActivityType, CoverageLimitType } from "@prisma/client";
import { z } from "zod";

import { effectiveDateSchema, turnoverSchema } from "../primitives";
import {
	OptionalGuaranteesInputSelectionSchema,
	OptionalGuaranteesSelectionSchema,
} from "./subscription.dto";

const addressSchema = z.object({
	address: z.string().optional(),
	postalCode: z.string().optional(),
	streetNumber: z.string().optional(),
	streetName: z.string().optional(),
	city: z.string().optional(),
	country: z.string().optional(),
});

const companySchema = z.object({
	siret: z.string().optional(),
	siren: z.string().optional(),
	nom_entreprise: z.string().optional(),
	RS: z.string().optional(),
	address: addressSchema.optional(),
});

const clientSchema = z.object({
	title: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	email: z.string().optional(),
	mobile: z.string().optional(),
	company: companySchema.optional(),
});

export const PricingInputSchema = z.object({
	produit: z.number(),
	chiffre_affaire: z.number(),
	chiffre_affaire_usa_can: z.number().optional(),
	activite_principale: z.number(),
	activite_complementaire: z.array(z.number()).optional(),
	opt_monde_entier: z.boolean().optional(),
	limite_garantie: z.number(),
	client: clientSchema.optional(),
});

export type PricingInputDto = z.infer<typeof PricingInputSchema>;

export const PricingResponseSchema = z.object({
	garanties_optionnelles: z.object({
		CYBER: z.object({
			base: z.object({
				Prime_TTC_Annuelle: z.number(),
				Prime_TTC_Mensuelle: z.number(),
				label: z.string(),
			}),
			option1: z.object({
				Prime_TTC_Annuelle: z.number(),
				Prime_TTC_Mensuelle: z.number(),
				label: z.string(),
			}),
			option2: z.object({
				Prime_TTC_Annuelle: z.number(),
				Prime_TTC_Mensuelle: z.number(),
				label: z.string(),
			}),
			option3: z.object({
				Prime_TTC_Annuelle: z.number(),
				Prime_TTC_Mensuelle: z.number(),
				label: z.string(),
			}),
			option4: z.object({
				Prime_TTC_Annuelle: z.number(),
				Prime_TTC_Mensuelle: z.number(),
				label: z.string(),
			}),
		}),
		Monde_entier: z
			.object({
				monde_entier_ttc: z.number(),
				monde_entier_ttc_Mensuelle: z.number(),
			})
			.optional(),
		RCE: z.object({
			rce_ttc: z.number(),
			rce_ttc_Mensuelle: z.number(),
		}),
		TDMI: z.object({
			Prime_TDMI_TTC: z.number(),
			Prime_TDMI_TTC_Mensuelle: z.number(),
		}),
	}),
	references: z.object({
		franchise_list: z.array(z.number()).optional(),
		limites_garanties: z.array(z.number()),
		opportunteRef: z.string().optional(),
		tarifRef: z.string(),
	}),
	ventilation: z.object({
		CYBER: z
			.object({
				Prime_TTC_Annuelle: z.number(),
				Prime_TTC_Mensuelle: z.number(),
				base: z
					.object({
						Prime_TTC_Annuelle: z.number(),
						label: z.string(),
					})
					.optional(),
				option1: z
					.object({
						Prime_TTC_Annuelle: z.number(),
						label: z.string(),
					})
					.optional(),
				option2: z
					.object({
						Prime_TTC_Annuelle: z.number(),
						label: z.string(),
					})
					.optional(),
				option3: z
					.object({
						Prime_TTC_Annuelle: z.number(),
						label: z.string(),
					})
					.optional(),
				option4: z
					.object({
						Prime_TTC_Annuelle: z.number(),
						label: z.string(),
					})
					.optional(),
			})
			.optional(),
		Monde_entier: z
			.object({
				monde_entier_ttc: z.number(),
			})
			.optional(),
		PJ: z
			.object({
				rpj_ttc: z.number(),
			})
			.optional(),
		RCE: z
			.object({
				rce_ttc: z.number(),
			})
			.optional(),
		RC_PRO: z.object({
			rc_pro_ttc: z.number(),
		}),
		TDMI: z
			.object({
				Prime_TDMI_TTC: z.number(),
			})
			.optional(),
		Total: z.object({
			total_ttc: z.number(),
			total_ttc_Mensuel: z.number(),
		}),
		franchise: z.number(),
	}),
});

export type PricingResponseDto = z.infer<typeof PricingResponseSchema>;

export const PricingSimulateInputSchema = z.object({
	produit: z.number({
		required_error: "Veuillez saisir l'ID du produit.",
	}),
	chiffre_affaire: z
		.number({
			required_error: "Veuillez saisir le chiffre d'affaires.",
		})
		.min(1, "Le montant de chiffre d'affaires minimum est de 1€"),
	limite_garantie: z.number({
		required_error: "Veuillez saisir la limite garantie.",
	}),
	activite_principale: z.number({
		required_error: "Veuillez saisir l'ID de l'activité principale.",
	}),
	activite_complementaire: z.array(z.number()).nullable(),
	opt_monde_entier: z.boolean(),
	chiffre_affaire_usa_can: z.number().optional(),
	opt_pj: z.boolean(),
	opt_rce: z.boolean(),
	opt_tdmi: z.boolean(),
	opt_cyber_base: z.boolean(),
	opt_cyber_1: z.boolean(),
	opt_cyber_2: z.boolean(),
	opt_cyber_3: z.boolean(),
	opt_cyber_4: z.boolean(),
});
export const PricingSimulateInputRouterSchema = z.object({
	turnover: turnoverSchema,
	coverageLimit: z.nativeEnum(CoverageLimitType),
	principalActivityType: z.nativeEnum(ActivityType),
	complementaryActivitiesTypes: z.array(z.nativeEnum(ActivityType)).optional(),
	optionalGuaranteesInputSelection: OptionalGuaranteesInputSelectionSchema,
});

export type PricingSimulateInputRouterDto = z.infer<
	typeof PricingSimulateInputRouterSchema
>;
export const PricingSimulateSchema = z.object({
	turnover: turnoverSchema,
	coverageLimit: z.nativeEnum(CoverageLimitType),
	principalActivityType: z.nativeEnum(ActivityType),
	complementaryActivitiesTypes: z.array(z.nativeEnum(ActivityType)).optional(),
	optionalGuaranteesInputSelection: OptionalGuaranteesInputSelectionSchema,
	optionalGuaranteesSelection: OptionalGuaranteesSelectionSchema,
});

export const PricingOutputSchema = z.object({
	annualPremiumPIT: z.number(),
	monthlyPremiumPIT: z.number(),
	worldWidePremiumPIT: z.number(),
	pjPremiumPIT: z.number(),
	rcpPremiumPIT: z.number(),
	deductible: z.number(),
});

export type PricingOutputDto = z.infer<typeof PricingOutputSchema>;

export const TransformedPricingOutputSchema = PricingResponseSchema.transform(
	(data) => {
		return {
			annualPremiumPIT: data.ventilation.Total.total_ttc,
			monthlyPremiumPIT: data.ventilation.Total.total_ttc_Mensuel,
			worldWidePremiumPIT: data.ventilation.Monde_entier?.monde_entier_ttc ?? 0,
			pjPremiumPIT: data.ventilation.PJ?.rpj_ttc ?? 0,
			rcpPremiumPIT: data.ventilation.RC_PRO.rc_pro_ttc,
			deductible: data.ventilation.franchise,
		};
	},
);

export type PricingSimulateDto = z.infer<typeof PricingSimulateSchema>;

export type PricingSimulateInputDto = z.infer<
	typeof PricingSimulateInputSchema
>;

export const ValidationResponseSchema = z.object({
	valid: z.boolean(),
});

export type ValidationResponseDto = z.infer<typeof ValidationResponseSchema>;

export const PricingDetailsSchema = z.object({
	desiredEffectiveDate: effectiveDateSchema,
	annualPremiumPIT: z.number(),
	monthlyPremiumPIT: z.number(),
	turnover: turnoverSchema,
	fileFees: z.number(),
	managementFees: z.number(),
	splittingFees: z.number(),
});

export type PricingDetailsDto = z.infer<typeof PricingDetailsSchema>;

export const FeesInputSchema = z.object({
	managementFees: z.coerce
		.number({
			required_error: "Veuillez entrer le montant des frais de gestion",
			invalid_type_error: "Veuillez entrer le montant des frais de gestion",
		})
		.default(15),

	fileFees: z.coerce
		.number({
			required_error: "Veuillez entrer le montant des frais de dossier",
			invalid_type_error: "Veuillez entrer le montant des frais de dossier",
		})
		.default(50),
	// using enums to represent boolean values because select inputs return strings
	// refer to https://github.com/colinhacks/zod/issues/1630
	isMonthly: z.enum(["true", "false"], {
		required_error: "Veuillez sélectionner la périodicité de paiement",
		invalid_type_error: "Veuillez sélectionner la périodicité de paiement",
	}),
	splittingFees: z.coerce
		.number({
			required_error: "Veuillez entrer le montant des frais de fractionnement",
			invalid_type_error:
				"Veuillez entrer le montant des frais de fractionnement",
		})
		.default(20)
		.optional(),
});

export type FeesInputDto = z.infer<typeof FeesInputSchema>;
