import type { WithNullableFields } from "@/utils";
import { LOI_TURNOVER_THRESHOLD } from "@/constants/constants.ts";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import type {
	BasicGuaranteeInputDto,
	CompanyDto,
	CoverageSelectionDto,
	FeesInputDto,
	GuaranteeRiskTypeDto,
	LegalRepresentiveDto,
	OptionalGuaranteesInputSelectionDto,
	SelectedGuaranteesDto,
	SubscriberInputDto,
} from "@repos/rcp-dtos";
import type { GuaranteeTypeDto } from "@repos/rcp-dtos/src/dtos/declaration.dto.ts";
import type { CoverageLimitDto } from "@repos/rcp-dtos/src/primitives";

type FormStoreState = {
	coverageSelection: WithNullableFields<CoverageSelectionDto>;
	fees: WithNullableFields<FeesInputDto>;
	basicGuarantee: WithNullableFields<BasicGuaranteeInputDto>;
	optionalGuaranteesInputSelection: OptionalGuaranteesInputSelectionDto;
	subscriber: WithNullableFields<SubscriberInputDto>;
	subscriptionId: string | null;
	currentStep: number;
};
const initialState: FormStoreState = {
	coverageSelection: {
		principalActivityType: undefined,
		complementaryActivitiesTypes: [],
		turnover: undefined,
		desiredEffectiveDate: undefined,
	},
	fees: {
		managementFees: 12,
		fileFees: 15,
		splittingFees: 50,
		isMonthly: "true",
	},
	basicGuarantee: {
		coverageLimit: "e100000",
	},
	optionalGuaranteesInputSelection: {
		worldWideTurnover: 0,
		selectedGuarantees: [],
	},
	subscriber: {
		companyName: undefined,
		sirenOrSiret: undefined,
		address: "",
		city: undefined,
		postalCode: undefined,
		country: undefined,
		phoneNumber: undefined,
		legalRepresentive: undefined,
	},
	subscriptionId: null,
	currentStep: 1,
};
export const formStore = create<FormStoreState & FormActions>()(
	persist(
		devtools((set, get) => ({
			...initialState,
			setCoverageSelection: (coverageSelection) => {
				//if turnover > 1500000, set coverage limit to e300000
				if (
					coverageSelection.turnover &&
					coverageSelection.turnover > LOI_TURNOVER_THRESHOLD
				) {
					set({ basicGuarantee: { coverageLimit: "e300000" } });
				}
				set({ coverageSelection });
			},
			setCoverageLimit: (coverageLimit) =>
				set({ basicGuarantee: { coverageLimit } }),
			setOptionalGuaranteesInputSelection: (optionalGuaranteesInputSelection) =>
				set({ optionalGuaranteesInputSelection }),
			toggleGuaranteeRisk: (guaranteeType, risk) => {
				const selectedGuarantees: SelectedGuaranteesDto =
					get().optionalGuaranteesInputSelection.selectedGuarantees;
				const newSelectedGuarantees: SelectedGuaranteesDto =
					selectedGuarantees.map((guarantee) =>
						guarantee.type === guaranteeType
							? {
									...guarantee,
									risksCovered: guarantee.risksCovered.includes(risk)
										? guarantee.risksCovered.filter((r) => r !== risk)
										: [...guarantee.risksCovered, risk],
								}
							: guarantee,
					);
				set({
					optionalGuaranteesInputSelection: {
						...get().optionalGuaranteesInputSelection,
						selectedGuarantees: newSelectedGuarantees,
					},
				});
			},
			setWorldWideTurnover: (worldWideTurnover) =>
				set({
					optionalGuaranteesInputSelection: {
						...get().optionalGuaranteesInputSelection,
						worldWideTurnover,
					},
				}),

			setFees: (fees) => set({ fees }),
			setCompany: (company) =>
				set({ subscriber: { ...get().subscriber, ...company } }),
			setLegalRepresentive: (legalRepresentive) =>
				set({
					subscriber: {
						...get().subscriber,
						legalRepresentive: legalRepresentive,
					},
				}),
			setSubscriptionId: (subscriptionId) => set({ subscriptionId }),
			setCurrentStep: (currentStep) => set({ currentStep }),
			reset: () => set(initialState),
		})),
		{
			name: "form-store",
		},
	),
);

type FormActions = {
	setCoverageSelection: (coverageSelection: CoverageSelectionDto) => void;
	setCoverageLimit: (coverageLimit: CoverageLimitDto) => void;
	setOptionalGuaranteesInputSelection: (
		optionalGuaranteesInputSelection: OptionalGuaranteesInputSelectionDto,
	) => void;
	toggleGuaranteeRisk: (
		guaranteeType: GuaranteeTypeDto,
		risk: GuaranteeRiskTypeDto,
	) => void;
	setWorldWideTurnover: (worldWideTurnover: number) => void;
	setFees: (fees: FeesInputDto) => void;
	setCompany: (company: CompanyDto) => void;
	setLegalRepresentive: (legalRepresentive: LegalRepresentiveDto) => void;
	setSubscriptionId: (subscriptionId: string) => void;
	setCurrentStep: (currentStep: number) => void;
	reset: () => void;
};
