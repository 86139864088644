import { CustomSpinner } from "@/components/CustomSpinner.tsx";
import { useRedirectBasedOnPaymentStatus } from "@/hooks/useRedirectBasedOnPaymentStatus.ts";
import { useRedirectBasedOnSignatureStatus } from "@/hooks/useRedirectBasedOnSignatureStatus.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { trpc } from "@/utils";
import Iframe from "react-iframe";
import { useSearchParams } from "react-router-dom";

export const PaymentWithCardPage = () => {
	useRedirectBasedOnSignatureStatus({});
	useRedirectBasedOnPaymentStatus({
		refetchInterval: 2000,
	});
	const subscriptionId = useSubscriptionIdFromQueryParam();

	const [searchParams] = useSearchParams();
	const partnershipId = searchParams.get("partnership_id");
	const { data, isLoading } = trpc.payment.paymentCard.useQuery({
		subscriptionId,
		bankInformation: {
			periodicity: "yearly",
		},
		partnershipId: partnershipId || "",
	});

	return (
		<div className=" flex h-full w-screen flex-col items-center gap-6  md:mt-12 ">
			<h1 className="text-2xl font-bold text-primary">
				Paiement par Carte Bancaire
			</h1>
			{isLoading ? (
				<CustomSpinner />
			) : data && data.urlPayment ? (
				<Iframe
					id="pay"
					url={data.urlPayment}
					className="h-full w-full"
					loading="eager"
				/>
			) : null}
		</div>
	);
};
