import { ActivityType } from "@prisma/client";
import { z } from "zod";

export const activityInfoSchema = z.object({
	id: z.string(),
	label: z.string(),
	value: z.nativeEnum(ActivityType),
	sectorId: z.string(),
	sectorLabel: z.string(),
});
export type ActivityInfoDto = z.infer<typeof activityInfoSchema>;
export const getActivitiesResponseSchema = z.array(activityInfoSchema);

export type GetActivitiesResponseDto = z.infer<
	typeof getActivitiesResponseSchema
>;
