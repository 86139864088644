import successIcon from "@/assets/successIcon.png";

export const SubsriptionSuccess = () => {
	return (
		<div className="flex h-full w-full flex-col items-center justify-center gap-10 ">
			<img src={successIcon} alt="success" />
			<p className=" text-center text-2xl font-bold">
				Félicitations ! vous êtes désormais assuré RCPRO.
			</p>
		</div>
	);
};
