import type { GuaranteeChoiceDto } from "@/pages/form/schemas";
import { useMemo } from "react";
import { SingleSelectCombobox } from "@/components/inputs/SingleSelectCombobox.tsx";
import { LOI_TURNOVER_THRESHOLD } from "@/constants/constants.ts";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { getCoverageLabel } from "@/utils/labelUtils.ts";

import type { CoverageLimitDto } from "@repos/rcp-dtos/src/primitives";
import { coverageLimitSchema } from "@repos/rcp-dtos/src/primitives";

export const CoverageLimitSelect = () => {
	const turnover = formStore((state) => state.coverageSelection.turnover);
	const coverageLimitsOptions = useMemo(() => {
		const validCoverageLimits = Object.keys(coverageLimitSchema.enum).filter(
			(key) => {
				//This
				if (turnover && turnover > LOI_TURNOVER_THRESHOLD) {
					return key !== "e100000" && key !== "e200000";
				}
				return true;
			},
		);
		return validCoverageLimits.map((key) => {
			return {
				label: getCoverageLabel(coverageLimitSchema.enum[key]),
				value: coverageLimitSchema.enum[key],
			};
		});
	}, []);
	// Updating store value because the value is used in the query in <SummarySection/>
	const setCoverageLimit = formStore((state) => state.setCoverageLimit);
	return (
		<SingleSelectCombobox<
			GuaranteeChoiceDto,
			{
				label: string;
				value: CoverageLimitDto;
			}
		>
			label="Limite de garantie RC Pro"
			name="basicGuarantee.coverageLimit"
			options={coverageLimitsOptions}
			onValueChange={(value: CoverageLimitDto) => setCoverageLimit(value)}
			required
		/>
	);
};
