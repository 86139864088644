import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { FaCircleInfo } from "react-icons/fa6";

interface InformationTooltipProps {
	content: string;
}

export function InformationTooltip({ content }: InformationTooltipProps) {
	return (
		<TooltipProvider>
			<Tooltip delayDuration={200}>
				<TooltipTrigger asChild>
					<span>
						<FaCircleInfo className="h-4 w-4 text-primary" />
					</span>
				</TooltipTrigger>
				<TooltipContent className="max-w-[200px]">
					<p>{content}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
}
