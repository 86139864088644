import { useSubscriptionInfoById } from "@/api/useSubscription.ts";
import { Button } from "@/components/ui/button.tsx";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog.tsx";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { cn, trpc } from "@/utils";
import { Download } from "lucide-react";

import type { GuaranteeTypeDto } from "@repos/rcp-dtos/src/dtos/declaration.dto.ts";

export const ContractualDocsButtonAndModal = () => {
	const subscriptionId = useSubscriptionIdFromQueryParam();
	const subscriptionDataQuery = useSubscriptionInfoById(subscriptionId);
	const docsQuery = trpc.documents.relevantDocuments.useQuery(
		{
			selectedGuaranteesTypes: subscriptionDataQuery.data?.guarantees.map(
				(guarantee) => guarantee.type,
			) as GuaranteeTypeDto[],
		},
		{
			enabled: subscriptionDataQuery.data?.guarantees !== undefined,
		},
	);
	console.log(subscriptionDataQuery.data);
	return (
		<Dialog>
			<DialogTrigger asChild>
				<div className="flex w-full cursor-pointer items-center justify-center gap-4  rounded-lg bg-primary/5 px-8 py-4">
					<Download className="icon mr-2 h-5 w-5 text-[#f70004]" />
					<p className={cn("font-semibold text-[#f70004] underline ")}>
						Télécharger vos documents contractuels
					</p>
				</div>
			</DialogTrigger>
			<DialogContent className="gap-12 sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>Documents Précontractuels</DialogTitle>
				</DialogHeader>
				<div className="grid gap-6 py-4">
					{docsQuery.data?.map((doc) => (
						<div key={doc.label} className=" flex items-center gap-4">
							<Download className="icon h-5 w-5 text-primary" />
							<a
								href={doc.url}
								className="text-primary "
								target="_blank"
								rel="noreferrer"
							>
								{doc.label}
							</a>
						</div>
					))}
				</div>
				<DialogFooter>
					<DialogClose asChild>
						<Button>Fermer</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
