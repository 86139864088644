import { CoverageChoicePage } from "@/pages/form/1/CoverageChoicePage/CoverageChoicePage.tsx";
import { GuaranteeChoicePage } from "@/pages/form/2/GuaranteeChoicePage.tsx";
import { ConfirmationDeclarationPage } from "@/pages/form/3/ConfirmationDeclarationPage.tsx";
import { CompanyInfoPage } from "@/pages/form/4/CompanyInfoPage.tsx";
import { LegalRepInfoPage } from "@/pages/form/5/LegalRepInfoPage.tsx";
import { SendQuoteAndLinkPage } from "@/pages/form/6/SendQuoteAndLinkPage.tsx";
import { BackupSessionPage } from "@/pages/form/BackupSessionPage";
import ResumeProgressRedirect from "@/pages/form/BackupSessionPage/components/ResumeProgressRedirect.tsx";

export enum BrokerRoute {
	CoverageChoice = "CoverageChoice",
	GuaranteeChoice = "GuaranteeChoice",
	ConfirmationDeclaration = "ConfirmationDeclaration",
	CompanyInfo = "CompanyInfo",
	LegalRepInfo = "LegalRepInfo",
	SendQuoteAndLink = "SendQuoteAndLink",
	BackupSession = "BackupSession",
}

const WithResumeProgressRedirect = ({ children }) => (
	<ResumeProgressRedirect>{children}</ResumeProgressRedirect>
);

export const brokerRoutes = {
	[BrokerRoute.CoverageChoice]: {
		path: "choix-couverture",
		element: (
			<WithResumeProgressRedirect>
				<CoverageChoicePage />
			</WithResumeProgressRedirect>
		),
		step: 1,
	},
	[BrokerRoute.GuaranteeChoice]: {
		path: "choix-garantie",
		element: (
			<WithResumeProgressRedirect>
				<GuaranteeChoicePage />
			</WithResumeProgressRedirect>
		),
		step: 2,
	},
	[BrokerRoute.ConfirmationDeclaration]: {
		path: "confirmation-declaration",
		element: (
			<WithResumeProgressRedirect>
				<ConfirmationDeclarationPage />
			</WithResumeProgressRedirect>
		),
		step: 3,
	},
	[BrokerRoute.CompanyInfo]: {
		path: "informations-entreprise",
		element: (
			<WithResumeProgressRedirect>
				<CompanyInfoPage />
			</WithResumeProgressRedirect>
		),
		step: 4,
	},
	[BrokerRoute.LegalRepInfo]: {
		path: "informations-representant-legal",
		element: (
			<WithResumeProgressRedirect>
				<LegalRepInfoPage />
			</WithResumeProgressRedirect>
		),
		step: 5,
	},
	[BrokerRoute.SendQuoteAndLink]: {
		path: "envoyer-devis-et-lien",
		element: (
			<WithResumeProgressRedirect>
				<SendQuoteAndLinkPage />
			</WithResumeProgressRedirect>
		),
		step: 6,
	},
	[BrokerRoute.BackupSession]: {
		path: "backup-session",
		element: <BackupSessionPage />,
		step: 0,
	},
};
