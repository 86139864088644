import type { CoverageLimitDto } from "@repos/rcp-dtos/src/primitives";

export function getCoverageLabel(coverage: CoverageLimitDto): string {
	switch (coverage) {
		case "e100000":
			return "100 000 €";
		case "e200000":
			return "200 000 €";
		case "e300000":
			return "300 000 €";
		case "e400000":
			return "400 000 €";
		case "e500000":
			return "500 000 €";
		case "e750000":
			return "750 000 €";
		case "e1000000":
			return "1 000 000 €";
		case "e1500000":
			return "1 500 000 €";
		case "e2000000":
			return "2 000 000 €";
		case "e3000000":
			return "3 000 000 €";
	}
}
