import { useState } from "react";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { CoverageInputSection } from "@/pages/form/1/CoverageChoicePage/CoverageInputSection.tsx";
import { FeesInputSection } from "@/pages/form/1/CoverageChoicePage/FeesInputSection.tsx";
import type { LandingPageDto} from "@/pages/form/schemas";
import { landingPageSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

export const CoverageChoicePage = () => {
	const { coverageSelection, fees, setCoverageSelection, setFees } = formStore(
		(state) => ({
			coverageSelection: state.coverageSelection,
			fees: state.fees,
			setCoverageSelection: state.setCoverageSelection,
			setFees: state.setFees,
			selectedGuarantees:
				state.optionalGuaranteesInputSelection.selectedGuarantees,
			worldWideTurnover:
				state.optionalGuaranteesInputSelection.worldWideTurnover,
		}),
	);
	const methods = useForm<LandingPageDto>({
		resolver: zodResolver(landingPageSchema),
		defaultValues: {
			coverageSelection: coverageSelection,
			fees: fees,
		},
		reValidateMode: "onChange",
	});
	const navigate = useCustomFormNavigate();
	const onSubmit = (data: LandingPageDto) => {
		setCoverageSelection(data.coverageSelection);
		setFees(data.fees);
		navigate("GuaranteeChoice");
	};
	const [nextButtonIsDisabled, setNextButtonIsDisabled] =
		useState<boolean>(true);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className={"relative grid h-full w-full grid-cols-12 "}
			>
				<CoverageInputSection
					setNextButtonIsDisabled={setNextButtonIsDisabled}
				/>
				<FeesInputSection
					nextButtonProps={{ disabled: nextButtonIsDisabled }}
				/>
			</form>
		</FormProvider>
	);
};
