import { trpc } from "@/utils";

export const useSubscriptionInfoById = (subscriptionId: string) => {
	//delay the query by 1 hour
	// const [canStartQuery, setCanStartQuery] = useState(false);
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setCanStartQuery(true);
	// 	}, 1000 * 20);
	// }, []);
	return trpc.subscriptions.getSubscription.useQuery(
		{ subscriptionId },
		{
			cacheTime: 1000 * 60 * 60,
			// enabled: canStartQuery,
		},
	);
};
