import { useEffect } from "react";
import { TextInput } from "@/components/inputs/TextInput.tsx";
import { trpc } from "@/utils";
import { useFormContext } from "react-hook-form";

import type { CompanyDto } from "@repos/rcp-dtos";

interface SirenInputProps {
	onQueryError?: (error: unknown) => void;
}

export function SirenInput({ onQueryError }: SirenInputProps) {
	const SirenMutation = trpc.siren.getCompanyInfoBySirenOrSiret.useMutation({
		onError: onQueryError,
	});
	const { setValue } = useFormContext<CompanyDto>();
	const onSirenInputChange = (value: string) => {
		if (value.length === 9) SirenMutation.mutate({ sirenOrSiret: value });
	};
	useEffect(() => {
		if (SirenMutation.data) {
			setValue("companyName", SirenMutation.data.legalName || "");
			setValue("address", SirenMutation.data.headquarters.address);
			setValue(
				"postalCode",
				SirenMutation.data.headquarters.detailedAddress.postalCode || "",
			);
			setValue(
				"city",
				SirenMutation.data.headquarters.detailedAddress.city || "",
			);
		}
	}, [SirenMutation.data, SirenMutation.isSuccess, setValue]);
	return (
		<TextInput<CompanyDto>
			label="SIREN"
			name="sirenOrSiret"
			onChange={onSirenInputChange}
			required
		/>
	);
}
