import type { ReactNode } from "react";
import type { Location } from "react-router-dom";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { Navigate, useLocation } from "react-router-dom";

type LocationState = {
	isSafe?: boolean;
};
type Props = {
	children: ReactNode;
};

export default function ResumeProgressRedirect({ children }: Props) {
	const navigate = useCustomFormNavigate();
	const currentStep = formStore((state) => state.currentStep);

	const hasResumeData = currentStep !== null;

	const location: Location<LocationState | undefined> = useLocation();

	const isSafe = location.state?.isSafe;

	if (!isSafe) {
		if (hasResumeData) {
			return <Navigate to={"/formulaire/backup-session"} />;
		} else {
			navigate("CoverageChoice");
		}
	}

	return <>{children}</>;
}
