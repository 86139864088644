import type { PaymentFormState } from "@/stores/usePaymentStore.ts";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { usePaymentStore } from "@/stores/usePaymentStore.ts";
import { cn } from "@/utils";

interface PaymentFrequencyCardProps {
	id: PaymentFormState["periodicity"];
	label: string;
	price?: number | null;
	isLoading: boolean;
	onSelect: () => void;
}

const PrimesSkeleton = function () {
	return <Skeleton className="h-[36px] w-full  font-medium" />;
};

export const PaymentFrequencyCard = ({
	id,
	label,
	price,
	isLoading,
	onSelect,
}: PaymentFrequencyCardProps) => {
	const { setPaymentFrequency, periodicity } = usePaymentStore(
		(state) => state,
	);
	return (
		<div
			className={cn(
				"flex flex-1 flex-col items-center justify-center rounded-xl border-2 bg-white px-6 py-3 hover:cursor-pointer",
				periodicity === id ? "border-primary" : "border-transparent",
			)}
			onClick={() => {
				setPaymentFrequency(id);
				onSelect();
			}}
		>
			<p className="text-nowrap text-2xl text-tertiary">{label}</p>
			{isLoading ? (
				<PrimesSkeleton />
			) : (
				<p className="text-3xl font-medium text-primary  ">{price} €</p>
			)}
		</div>
	);
};
