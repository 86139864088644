import { Button } from "@/components/ui/button";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { useResetStores } from "@/hooks/resetAllStores.ts";
import { brokerRoutes } from "@/pages/form/routes";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { Navigate } from "react-router-dom";

export const BackupSessionPage = () => {
	const navigate = useCustomFormNavigate();

	const resetStores = useResetStores();

	const currentStep = formStore((state) => state.currentStep);

	const onResumeProgress = () => {
		if (!currentStep) {
			return <>{navigate("CoverageChoice")}</>;
		}

		const currentStepIndex = Object.values(brokerRoutes).findIndex(
			(route) => route.step === currentStep,
		);
		if (currentStepIndex === -1) {
			return <>{navigate("CoverageChoice")}</>;
		}
		navigate(
			(
				Object.keys(brokerRoutes)[currentStepIndex] as keyof typeof brokerRoutes
			).toString() as keyof typeof brokerRoutes,
		);
	};

	const hasResumeData = currentStep !== null;
	if (!hasResumeData) {
		return <Navigate to={"/"} />;
	} else
		return (
			<>
				<div className="flex h-[60svh] flex-col items-center justify-center lg:h-full">
					<div className="flex max-w-screen-lg flex-col gap-16 px-4 md:px-0">
						<h1 className="text-center text-lg font-bold leading-6 text-black text-primary md:text-3xl md:leading-10">
							Souhaitez-vous poursuivre votre <br /> souscription ou bien
							souscrire à nouveau ?
						</h1>
						<div className="flex flex-row justify-center gap-2 md:flex-row md:gap-7">
							<Button
								variant="outline"
								className="flex-1 p-8"
								onClick={(e) => {
									e.preventDefault();
									resetStores();
									navigate("CoverageChoice");
								}}
							>
								Souscrire à nouveau
							</Button>
							<Button className="flex-1 p-8" onClick={onResumeProgress}>
								Poursuivre
							</Button>
						</div>
					</div>
				</div>
			</>
		);
};
