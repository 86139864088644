import type { z } from "zod";

import { SubscriptionSchema } from "@repos/rcp-dtos";

export const landingPageSchema = SubscriptionSchema.pick({
	coverageSelection: true,
	fees: true,
});
export type LandingPageDto = z.infer<typeof landingPageSchema>;

export const guaranteeChoiceSchema = SubscriptionSchema.pick({
	optionalGuaranteesInputSelection: true,
	basicGuarantee: true,
});
export type GuaranteeChoiceDto = z.infer<typeof guaranteeChoiceSchema>;
