import type { LandingPageDto } from "@/pages/form/schemas";
import type { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";
import { DateInput } from "@/components/inputs/DateInput/DateInput.tsx";
import { MultiSelectCombobox } from "@/components/inputs/MultiSelectCombobox.tsx";
import PriceInput from "@/components/inputs/PriceInput.tsx";
import { SingleSelectCombobox } from "@/components/inputs/SingleSelectCombobox.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useDebounce } from "@/hooks/useDebounce.ts";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { trpc } from "@/utils";
import { addDays } from "date-fns";
import { useFormContext, useWatch } from "react-hook-form";

import type { ActivityInfoDto } from "@repos/rcp-dtos";

interface CoverageInputSectionProps {
	setNextButtonIsDisabled: Dispatch<SetStateAction<boolean>>;
}

export const CoverageInputSection = ({
	setNextButtonIsDisabled,
}: CoverageInputSectionProps) => {
	const activitiesQuery = trpc.activities.getActivities.useQuery({});

	const { selectedGuarantees, worldWideTurnover } = formStore((state) => ({
		selectedGuarantees:
			state.optionalGuaranteesInputSelection.selectedGuarantees,
		worldWideTurnover: state.optionalGuaranteesInputSelection.worldWideTurnover,
	}));
	const { setValue, setError } = useFormContext<LandingPageDto>();
	const validateTurnoverMutation =
		trpc.validations.validateTurnover.useMutation({
			retry: false,
			onError: (error) => {
				toast({
					title: "Erreur",
					description: error.message,
					variant: "destructive",
				});
				setError("coverageSelection.turnover", {
					type: "manual",
					message: error.message,
				});
			},
			onSuccess: (data) => {
				setNextButtonIsDisabled(!data.isValid);
			},
		});
	const turnover = useWatch({
		name: "coverageSelection.turnover",
	});
	const selectedPrincipalActivity = useWatch({
		name: "coverageSelection.principalActivityType",
	});
	const complementaryActivitiesTypes = useWatch({
		name: "coverageSelection.complementaryActivitiesTypes",
	});
	const debouncedTurnover = useDebounce(turnover, 600);
	useEffect(() => {
		if (debouncedTurnover) {
			// set the next button to disabled when input data changes
			setNextButtonIsDisabled(true);
			// selectedGuarantees and worldWideTurnover are not used in the mutation because if the user
			// moves to the next page, inputs invalid data for both of them and then goes back to this page
			// the next button will remain disabled even if the turnover is valid
			// because the selectedGuarantees and worldWideTurnover are stored in the form store, are not valid and cant be changed
			validateTurnoverMutation.mutate({
				turnover: debouncedTurnover,
				principalActivityType: selectedPrincipalActivity,
				complementaryActivitiesTypes: complementaryActivitiesTypes || [],
				optionalGuaranteesInputSelection: {
					selectedGuarantees: [],
					worldWideTurnover: 1,
				},
			});
		}
	}, [
		debouncedTurnover,
		selectedPrincipalActivity,
		complementaryActivitiesTypes,
		selectedGuarantees,
		worldWideTurnover,
	]);
	return (
		<div
			className={
				"col-span-7 flex h-full  flex-col justify-center  gap-6 p-16 pr-32"
			}
		>
			<h1 className="text-3xl font-bold text-rcp-secondary">
				Choix de la couverture
			</h1>
			<div className="flex w-full flex-col items-center justify-evenly gap-10">
				{activitiesQuery.data && (
					<>
						<SingleSelectCombobox<LandingPageDto, ActivityInfoDto>
							label="Activité principale"
							name="coverageSelection.principalActivityType"
							options={activitiesQuery.data}
							onValueChange={() => {
								setValue("coverageSelection.complementaryActivitiesTypes", []);
							}}
							required
						/>
						<MultiSelectCombobox<LandingPageDto, ActivityInfoDto>
							label="Activité(s) Complémentaire(s)"
							name="coverageSelection.complementaryActivitiesTypes"
							// removes the principal activity from the options
							options={activitiesQuery.data.filter(
								(activity) => activity.value !== selectedPrincipalActivity,
							)}
						/>
						<PriceInput<LandingPageDto>
							label="Chiffre d’affaires"
							name="coverageSelection.turnover"
							required
						/>
						<DateInput<LandingPageDto>
							label="Date d’effet souhaitée"
							name="coverageSelection.desiredEffectiveDate"
							required
							minDate={addDays(new Date(), 1)}
							maxDate={addDays(new Date(), 91)}
						/>
					</>
				)}
			</div>
		</div>
	);
};
