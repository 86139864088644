import type { GuaranteeChoiceDto } from "@/pages/form/schemas";
import { useState } from "react";
import { SummarySection } from "@/components/SummarySection.tsx";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { GuaranteesSelectionSection } from "@/pages/form/2/GuaranteesSelectionSection.tsx";
import { guaranteeChoiceSchema } from "@/pages/form/schemas";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

export const GuaranteeChoicePage = () => {
	const { basicGuarantee, optionalGuaranteesInputSelection } = formStore(
		(state) => ({
			basicGuarantee: state.basicGuarantee,
			optionalGuaranteesInputSelection: state.optionalGuaranteesInputSelection,
		}),
	);
	const methods = useForm<GuaranteeChoiceDto>({
		resolver: zodResolver(guaranteeChoiceSchema),
		defaultValues: {
			basicGuarantee: basicGuarantee,
			optionalGuaranteesInputSelection: optionalGuaranteesInputSelection,
		},
	});
	const navigate = useCustomFormNavigate();
	const [nextButtonIsDisabled, setNextButtonIsDisabled] =
		useState<boolean>(true);
	const onSubmit = () => {
		navigate("ConfirmationDeclaration");
	};
	const handleReturnButton = () => {
		navigate("CoverageChoice");
	};
	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className={"relative grid h-full w-full grid-cols-12"}
			>
				<GuaranteesSelectionSection
					setNextButtonIsDisabled={setNextButtonIsDisabled}
				/>
				<SummarySection
					handleReturnButton={handleReturnButton}
					nextButtonProps={{ disabled: nextButtonIsDisabled }}
					onQuerySuccess={() => {
						setNextButtonIsDisabled(false);
					}}
				/>
			</form>
		</FormProvider>
	);
};
