import type { ReactNode } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";

interface TextInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	className?: string;
	type?: HTMLInputElement["type"];
	endAdornment?: ReactNode;
	required?: boolean;
	onChange?: (value: string) => void;
}

export function TextInput<T extends FieldValues>({
	label,
	className,
	type = "text",
	name,
	endAdornment,
	disabled,
	required,
	onChange,
	...props
}: TextInputProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			name={name}
			control={control}
			{...props}
			render={({ field }) => (
				<FormItem className={cn("flex w-full flex-col", className)}>
					<FormLabel className="text-2xl font-medium text-primary">
						{label} {required && <span className="text-red-500">*</span>}
					</FormLabel>
					<FormControl>
						<Input
							type={type}
							disabled={disabled}
							{...field}
							className={cn(
								"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-2xl border-gray-300 bg-white p-8 font-normal text-black",
								!field.value && "text-muted-foreground",
							)}
							onChange={(e) => {
								field.onChange(e.target.value);
								onChange?.(e.target.value);
							}}
							endAdornment={endAdornment}
						/>
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
