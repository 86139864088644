import type {ReactNode} from "react";
import {CustomSpinner} from "@/components/CustomSpinner.tsx";
import useAuthCheck from "@/hooks/useAuthCheck.tsx";
import {useAuthQueryParamsSetter} from "@/hooks/useAuthQueryParamsSetter.ts";

interface ProtectedRouteProps {
    children: ReactNode;
}

export const PrivateRoute = ({children}: ProtectedRouteProps) => {
    useAuthQueryParamsSetter();
    const loading = useAuthCheck();

    if (loading) {
        return <CustomSpinner/>;
    }

    return <>{children}</>;
};
