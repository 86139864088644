import type { GuaranteeChoiceDto } from "@/pages/form/schemas";
import { useCallback, useState } from "react";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { FormItem, FormLabel } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { cn } from "@/utils";
import { useFormContext, useWatch } from "react-hook-form";
import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";
import { NumericFormat } from "react-number-format";

import type {
	GuaranteeResponseDto,
	GuaranteeRiskTypeDto,
	SelectedGuaranteesDto,
} from "@repos/rcp-dtos";

interface SubGuaranteeCheckboxProps
	extends React.HTMLProps<HTMLButtonElement> {}

const SubGuaranteeCheckbox = ({
	label,
	checked,
	onChange,
	disabled,
}: SubGuaranteeCheckboxProps) => (
	<div className="flex items-center gap-2">
		<Checkbox
			id={label}
			checked={checked}
			className="h-5 w-5 disabled:opacity-100"
			disabled={disabled}
			onClick={onChange}
		/>
		<FormLabel
			htmlFor={label}
			className="font-normal  peer-disabled:opacity-100"
		>
			{label}
		</FormLabel>
	</div>
);

interface OptionalGuaranteeCardProps {
	guarantee: GuaranteeResponseDto;
	isLoading?: boolean;
}

export const OptionalGuaranteeCard = ({
	guarantee,
}: OptionalGuaranteeCardProps) => {
	const [displayedSection, setDisplayedSection] = useState<
		"FRANCHISE" | "DESCRIPTION" | "SUBGUARANTEES"
	>(guarantee.type === "CYBER" ? "SUBGUARANTEES" : "FRANCHISE");
	const { setValue, register } = useFormContext<GuaranteeChoiceDto>();
	const selectedGuarantees: SelectedGuaranteesDto = useWatch({
		name: "optionalGuaranteesInputSelection.selectedGuarantees",
	});
	const worldWideTurnover = useWatch({
		name: "optionalGuaranteesInputSelection.worldWideTurnover",
	});
	const isGuaranteeSelected =
		selectedGuarantees.find((g) => g.type === guarantee.type) !== undefined;
	const {
		setOptionalGuaranteesInputSelection,
		setWorldWideTurnover,
		toggleGuaranteeRisk,
	} = formStore((state) => {
		return {
			setOptionalGuaranteesInputSelection:
				state.setOptionalGuaranteesInputSelection,
			setWorldWideTurnover: state.setWorldWideTurnover,
			toggleGuaranteeRisk: state.toggleGuaranteeRisk,
		};
	});
	const handleSelect = useCallback(() => {
		let newSelectedGuarantees: SelectedGuaranteesDto = [];
		if (isGuaranteeSelected) {
			newSelectedGuarantees = selectedGuarantees.filter(
				(g) => g.type !== guarantee.type,
			);
			setValue(
				"optionalGuaranteesInputSelection.selectedGuarantees",
				newSelectedGuarantees,
			);
			setValue(
				"optionalGuaranteesInputSelection.worldWideTurnover",
				guarantee.type === "WORLDWIDE" ? 0 : worldWideTurnover,
			);
			setOptionalGuaranteesInputSelection({
				selectedGuarantees: newSelectedGuarantees,
				worldWideTurnover:
					guarantee.type === "WORLDWIDE" ? 0 : worldWideTurnover,
			});
		} else {
			newSelectedGuarantees = [
				...selectedGuarantees,
				{ type: guarantee.type, risksCovered: [] },
			];
			setValue(
				"optionalGuaranteesInputSelection.selectedGuarantees",
				newSelectedGuarantees,
			);
			setOptionalGuaranteesInputSelection({
				selectedGuarantees: newSelectedGuarantees,
				worldWideTurnover:
					guarantee.type === "WORLDWIDE" ? 0 : worldWideTurnover,
			});
		}
	}, [
		isGuaranteeSelected,
		guarantee,
		selectedGuarantees,
		setOptionalGuaranteesInputSelection,
	]);

	return (
		<li className="flex w-full  flex-col justify-center gap-4 rounded-2xl bg-white p-6">
			<div className="flex w-full justify-between gap-10 border-b border-b-gray-300">
				<div className="flex flex-1  items-center gap-3  pb-2">
					<img src={guarantee.iconUrl} alt={guarantee.label} className="w-8" />
					<h2 className="text-lg font-bold ">{guarantee.label}</h2>
				</div>
				<div className="flex-2 flex items-center gap-3">
					<p className="text-nowrap text-xl font-bold text-primary">
						{guarantee.addedMonthlyPremium} €/mois
					</p>
					{isGuaranteeSelected ? (
						<IoMdRemoveCircle
							className="h-8 w-8 text-primary"
							onClick={handleSelect}
						/>
					) : (
						<IoMdAddCircle
							className="h-8 w-8 text-primary"
							onClick={handleSelect}
						/>
					)}
				</div>
			</div>
			<div className="flex w-full cursor-pointer justify-between gap-10 border-b border-black text-lg font-bold">
				{guarantee.type === "CYBER" && (
					<span
						className={cn(
							displayedSection === "SUBGUARANTEES"
								? "border-b-2 border-primary text-primary"
								: "text-black",
						)}
						onClick={() => setDisplayedSection("SUBGUARANTEES")}
					>
						Sous garanties
					</span>
				)}

				<span
					className={cn(
						displayedSection === "FRANCHISE"
							? "border-b-2 border-primary text-primary"
							: "text-black",
					)}
					onClick={() => setDisplayedSection("FRANCHISE")}
				>
					{guarantee.type !== "CYBER"
						? "Franchise et Limite de Garantie"
						: "Franchise et LOI"}
				</span>
				{guarantee.type !== "CYBER" && (
					<span
						className={cn(
							displayedSection === "DESCRIPTION"
								? "border-b-2 border-primary text-primary"
								: "text-black",
						)}
						onClick={() => setDisplayedSection("DESCRIPTION")}
					>
						Description
					</span>
				)}
			</div>
			<div className="flex w-full justify-between gap-28">
				{displayedSection === "FRANCHISE" ? (
					<div className="flex w-full justify-between gap-28">
						{guarantee.type === "WORLDWIDE" ? (
							<>
								<p className="flex-1 text-sm font-medium">
									Cette garantie est applicable sous réserve que le chiffre
									d’affaires généré aux USA/Canada n’excède pas 30% du chiffre
									d’affaires total.
								</p>
								<FormItem>
									<FormLabel>Chiffre d’Affaires USA/Canada</FormLabel>
									<Input
										className="flex-1 rounded-xl border-primary py-7 text-lg font-semibold"
										{...register(
											"optionalGuaranteesInputSelection.worldWideTurnover",
										)}
										onChange={(e) => {
											register(
												"optionalGuaranteesInputSelection.worldWideTurnover",
											).onChange(e);
											setWorldWideTurnover(parseInt(e.target.value));
										}}
										disabled={!isGuaranteeSelected}
										type="number"
									/>
								</FormItem>
							</>
						) : (
							<div className="flex flex-1  flex-col justify-center gap-2">
								<div className="flex w-full justify-between gap-28">
									<div className="flex flex-1  flex-col justify-center gap-2">
										<p className="font-medium">Limite de garantie</p>
										<div className="flex flex-col items-center justify-center gap-2">
											<p className="w-full rounded-xl bg-primary/40 px-10 py-5 text-center text-lg font-bold text-white">
												<NumericFormat
													value={guarantee.coverageLimit}
													displayType={"text"}
													thousandSeparator={" "}
													suffix={" €"}
												/>
											</p>
										</div>
									</div>
									<div className="flex  flex-1 flex-col justify-center gap-2">
										<p className="font-medium">Franchise</p>
										<p className="w-full rounded-xl bg-primary/40 px-10 py-5 text-center text-lg font-bold text-white ">
											<NumericFormat
												value={guarantee.deductible}
												displayType={"text"}
												thousandSeparator={" "}
												suffix={" €"}
											/>
										</p>
									</div>
								</div>
								{guarantee.isCoverageLimitFixed && (
									<p className="text-sm text-gray-500">
										La limite pour cette garantie est fixée
									</p>
								)}
							</div>
						)}
					</div>
				) : (
					<div className="flex flex-1  flex-col justify-center gap-2">
						<ul className="flex flex-col gap-2">
							{guarantee.risksCovered.map((risk) => {
								const isRiskSelected = selectedGuarantees
									.find((g) => g.type === guarantee.type)
									?.risksCovered.includes(risk.type);
								return (
									<li
										key={risk.label}
										className="flex w-full items-center justify-between gap-12"
									>
										<SubGuaranteeCheckbox
											label={risk.label}
											disabled={!risk.isOptional || !isGuaranteeSelected}
											checked={!risk.isOptional || isRiskSelected}
											onChange={() => {
												const guaranteeIndex = selectedGuarantees.findIndex(
													(g) => g.type === guarantee.type,
												);
												let riskIndex: number | undefined;
												let newRisks: GuaranteeRiskTypeDto[] = [];
												//if guarantee is selected, find the riskIndex
												if (
													guaranteeIndex !== -1 &&
													selectedGuarantees[guaranteeIndex] !== undefined
												) {
													riskIndex = selectedGuarantees[
														guaranteeIndex
													]?.risksCovered.findIndex((r) => r === risk.type);
												}
												//if risk is selected, remove it from the array
												if (
													riskIndex !== -1 &&
													riskIndex !== undefined &&
													selectedGuarantees[guaranteeIndex]
												) {
													// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
													newRisks = selectedGuarantees[
														guaranteeIndex
													]!.risksCovered.filter((r) => r !== risk.type);
												} else if (selectedGuarantees[guaranteeIndex]) {
													// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
													selectedGuarantees[guaranteeIndex]!.risksCovered.push(
														risk.type,
													);

													newRisks =
														selectedGuarantees[guaranteeIndex]!.risksCovered;
												}
												setValue(
													`optionalGuaranteesInputSelection.selectedGuarantees.${guaranteeIndex}.risksCovered`,
													newRisks,
												);
												toggleGuaranteeRisk(guarantee.type, risk.type);
											}}
										/>
										{risk.addedMonthlyPremium !== null &&
											risk.addedMonthlyPremium !== 0 && (
												<p className="text-nowrap text-lg font-bold text-primary">
													{risk.addedMonthlyPremium} €/mois
												</p>
											)}
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>
		</li>
	);
};
