import { GuaranteeType } from "@prisma/client";
import { z } from "zod";

export const GuaranteeTypeSchema = z.nativeEnum(GuaranteeType);
export type GuaranteeTypeDto = z.infer<typeof GuaranteeTypeSchema>;
export const GuaranteeTypesSchema = z.array(GuaranteeTypeSchema);
export const getDeclarationsInputSchema = z.object({
	guaranteeTypes: GuaranteeTypesSchema,
});

export const getDeclarationsOutputSchema = z.object({
	declarations: z.array(
		z.object({
			type: GuaranteeTypeSchema,
			description: z.array(z.string()),
		}),
	),
});
