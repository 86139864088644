import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface PriceInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	placeholder?: string;
	required?: boolean;
}

export default function PriceInput<T extends FieldValues>({
	label,
	placeholder,
	name,
	required,
}: PriceInputProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			name={name}
			control={control}
			render={({ field }) => (
				<FormItem className="flex w-full flex-col">
					<FormLabel className="text-2xl font-medium text-primary">
						{label} {required && <span className="text-red-500">*</span>}
					</FormLabel>
					<NumericFormat
						className="w-full rounded-2xl border-gray-300 bg-white p-8 font-normal text-black "
						thousandSeparator=" "
						placeholder={placeholder}
						inputMode="decimal"
						value={field.value}
						onValueChange={({ floatValue }) => {
							field.onChange(floatValue);
						}}
						customInput={Input}
						suffix=" €"
					/>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
