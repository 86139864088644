import type { InputProps } from "@/components/ui/input";
import * as React from "react";
import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/utils";
import { CheckIcon, ChevronsUpDown } from "lucide-react";
import * as RPNInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { ScrollArea } from "../ui/scroll-area";

type PhoneInputProps = Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	"onChange" | "value"
> &
	Omit<RPNInput.Props<typeof RPNInput.default>, "onChange"> & {
		onChange?: (value: RPNInput.Value) => void;
	};

const PhoneInputCountrySelect: React.ForwardRefExoticComponent<PhoneInputProps> =
	React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
		({ className, onChange, ...props }, ref) => {
			return (
				<RPNInput.default
					ref={ref}
					className={cn(
						"flex w-full rounded-2xl border-2 border-gray-300 bg-white font-normal text-black",
						className,
					)}
					flagComponent={FlagComponent}
					countrySelectComponent={CountrySelect}
					inputComponent={InputComponent}
					/**
					 * Handles the onChange event.
					 *
					 * react-phone-number-input might trigger the onChange event as undefined
					 * when a valid phone number is not entered. To prevent this,
					 * the value is coerced to an empty string.
					 *
					 * @param {E164Number | undefined} value - The entered value
					 */
					onChange={(value) => onChange?.(value || (value as RPNInput.Value))}
					{...props}
				/>
			);
		},
	);
PhoneInputCountrySelect.displayName = "PhoneInput";

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, ...props }, ref) => (
		<Input
			className={cn(
				"m-0 ml-0 h-14 w-full rounded-2xl border-none p-8",
				className,
			)}
			{...props}
			ref={ref}
		/>
	),
);
InputComponent.displayName = "InputComponent";

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
	disabled?: boolean;
	value: RPNInput.Country;
	onChange: (value: RPNInput.Country) => void;
	options: CountrySelectOption[];
};

const CountrySelect = ({
	disabled,
	value,
	onChange,
	options,
}: CountrySelectProps) => {
	const handleSelect = React.useCallback(
		(country: RPNInput.Country) => {
			onChange(country);
		},
		[onChange],
	);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					type="button"
					variant={"outline"}
					className={cn(
						"gap- mr-0 flex h-full items-center justify-center border-none",
					)}
					disabled={disabled}
				>
					<FlagComponent country={value} countryName={value} />
					<ChevronsUpDown
						className={cn(
							"-mr-2 h-4 w-4 opacity-50",
							disabled ? "hidden" : "opacity-100",
						)}
					/>
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[300px] p-0">
				<Command>
					<CommandList>
						<ScrollArea className="h-72 bg-white">
							<CommandInput placeholder="Chercher un pays..." />
							<CommandEmpty>Aucun pays trouvé.</CommandEmpty>
							<CommandGroup>
								{options
									.filter((x) => x.value)
									.map((option) => (
										<CommandItem
											className="gap-2"
											key={option.value}
											onSelect={() => handleSelect(option.value)}
										>
											<FlagComponent
												country={option.value}
												countryName={option.label}
											/>
											<span className="flex-1 text-sm">{option.label}</span>
											{option.value && (
												<span className="text-sm text-foreground/50">
													{`+${RPNInput.getCountryCallingCode(option.value)}`}
												</span>
											)}
											<CheckIcon
												className={cn(
													"ml-auto h-4 w-4",
													option.value === value ? "opacity-100" : "opacity-0",
												)}
											/>
										</CommandItem>
									))}
							</CommandGroup>
						</ScrollArea>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
	const Flag = flags[country];

	return (
		<span className="flex h-4 w-6 overflow-hidden rounded-sm bg-foreground/20">
			{Flag && <Flag title={countryName} />}
		</span>
	);
};
FlagComponent.displayName = "FlagComponent";

export { PhoneInputCountrySelect };
