import type { ProspectRoute } from "@/routes/private.tsx";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import type { BrokerRoute } from "@/pages/form/routes";
import { prospectRoutes } from "@/routes/private.tsx";
import { useNavigate } from "react-router-dom";

type StateType = {
	[key: string]: string | number | boolean;
};

interface UseCustomNavigateWithSubIDProps {
	queryParam?: string;
}

export const useCustomNavigateWithSubID = ({
	queryParam,
}: UseCustomNavigateWithSubIDProps) => {
	const navigate = useNavigate();
	const subscriptionId = useSubscriptionIdFromQueryParam();

	return (
		route: keyof typeof ProspectRoute | BrokerRoute,
		state?: StateType,
	) => {
		let path: string;
		if (queryParam) {
			path = `${prospectRoutes[route].path}?subscription_id=${subscriptionId}&${queryParam}`;
		} else {
			path = `${prospectRoutes[route].path}?subscription_id=${subscriptionId}`;
		}
		navigate(path, {
			state: { ...state, isSafe: true, test: "test" },
		});
		window.scroll(0, 0);
	};
};
