import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { PhoneInputCountrySelect } from "@/components/ui/phone-input-country-select.tsx";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";

interface PhoneInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	className?: string;
	required?: boolean;
}

export function PhoneInput<T extends FieldValues>({
	label,
	className,
	name,
	required,
	...props
}: PhoneInputProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			{...props}
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem className={cn("flex w-full flex-col", className)}>
					<FormLabel className="text-2xl font-medium text-primary">
						{label} {required && <span className="text-red-500">*</span>}
					</FormLabel>
					<PhoneInputCountrySelect
						{...field}
						defaultCountry="FR"
						onChange={(value) => field.onChange(value)}
					/>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
