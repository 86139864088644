import { GuaranteeRiskType } from "@prisma/client";
import { z } from "zod";

import { GuaranteeTypeSchema } from "./declaration.dto";
import { PricingSimulateInputRouterSchema } from "./pricing.dto";

export const GuaranteeRiskTypeSchema = z.nativeEnum(GuaranteeRiskType);
export type GuaranteeRiskTypeDto = z.infer<typeof GuaranteeRiskTypeSchema>;
export const RiskCoveredResponseSchema = z.object({
	type: GuaranteeRiskTypeSchema,
	label: z.string(),
	addedMonthlyPremium: z.number().nullable(),
	isOptional: z.boolean(),
});

export const GuaranteeResponseSchema = z.object({
	type: GuaranteeTypeSchema,
	label: z.string(),
	description: z.string(),
	isCoverageLimitFixed: z.boolean(),
	coverageLimit: z.number().nullable(),
	isDeductibleFixed: z.boolean(),
	deductible: z.number().nullable(),
	addedMonthlyPremium: z.number().nullable(),
	iconUrl: z.string(),
	order: z.number(),
	risksCovered: z.array(RiskCoveredResponseSchema),
});

export type GuaranteeResponseDto = z.infer<typeof GuaranteeResponseSchema>;

export const BasicGuarenteesResponseSchema = z.object({
	basicGuarantees: z.array(GuaranteeResponseSchema),
});

export const OptionalGuarenteesResponseSchema = z.object({
	optionalGuarantees: z.array(GuaranteeResponseSchema),
});

export type OptionalGuarenteesResponseDto = z.infer<
	typeof OptionalGuarenteesResponseSchema
>;

export type BasicGuarenteesResponseDto = z.infer<
	typeof BasicGuarenteesResponseSchema
>;

const BasicGuaranteesSchema = z.array(GuaranteeResponseSchema);
export const FullGuaranteeResponseSchema = z.object({
	basicGuarantees: BasicGuaranteesSchema,
	optionalGuarantees: z.array(GuaranteeResponseSchema),
});
export type FullGuaranteeResponseDto = z.infer<
	typeof FullGuaranteeResponseSchema
>;
export const GetGuaranteeInputSchema = z.object({
	pricing: PricingSimulateInputRouterSchema,
});
