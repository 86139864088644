import { z } from "zod";

import {
	complementaryActivitiesTypesSchema,
	principalActivityTypeSchema,
	turnoverSchema,
} from "../primitives";
import { OptionalGuaranteesInputSelectionSchema } from "./subscription.dto";

export const TurnoverValidationInputDto = z.object({
	turnover: turnoverSchema,
	principalActivityType: principalActivityTypeSchema,
	complementaryActivitiesTypes: complementaryActivitiesTypesSchema,
	optionalGuaranteesInputSelection: OptionalGuaranteesInputSelectionSchema,
});

export const TurnoverValidationResponseDto = z.object({
	isValid: z.boolean(),
	message: z.string().optional(),
});

export type TurnoverValidationResponse = z.infer<
	typeof TurnoverValidationResponseDto
>;
