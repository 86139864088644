import { SummarySection } from "@/components/SummarySection.tsx";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { SubsriberInfoSection } from "@/pages/form/4/SubsriberInfoSection.tsx";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type { CompanyDto } from "@repos/rcp-dtos";
import { CompanySchema } from "@repos/rcp-dtos";

export const CompanyInfoPage = () => {
	const { subscriber, setCompany } = formStore((state) => ({
		subscriber: state.subscriber,
		setCompany: state.setCompany,
	}));
	const methods = useForm<CompanyDto>({
		resolver: zodResolver(CompanySchema),
		defaultValues: {
			...subscriber,
		},
	});
	const navigate = useCustomFormNavigate();

	const onSubmit = (data: CompanyDto) => {
		setCompany(data);
		navigate("LegalRepInfo");
	};
	const handleReturnButton = () => {
		navigate("ConfirmationDeclaration");
	};
	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className={"relative grid h-full w-full grid-cols-12"}
			>
				<SubsriberInfoSection />
				<SummarySection handleReturnButton={handleReturnButton} />
			</form>
		</FormProvider>
	);
};
