import type {
	FieldValues,
	Path,
	PathValue,
	UseControllerProps,
} from "react-hook-form";
import { useCallback, useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Spinner } from "@/components/ui/Spinner.tsx";
import { useDebounce } from "@/hooks/useDebounce";
import { cn, trpc } from "@/utils";
import { Check } from "lucide-react";
import { useFormContext } from "react-hook-form";

interface AddressProperty<T extends FieldValues>
	extends UseControllerProps<T> {}

interface AdressInputCompanyProps<T extends FieldValues>
	extends UseControllerProps<T> {
	label: string;
	countryProps?: AddressProperty<T>;
	cityProps: AddressProperty<T>;
	postalCodeProps: AddressProperty<T>;
	placeholder?: string;
	disabled?: boolean;
	required?: boolean;
}

export function AddressInput<T extends FieldValues>({
	name,
	label,
	placeholder,
	disabled,
	cityProps,
	countryProps,
	postalCodeProps,
	required,
}: AdressInputCompanyProps<T>) {
	const { control, setValue, getValues } = useFormContext<T>();
	const [inputValue, setInputValue] = useState<string>(getValues(name) || "");
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const debouncedSearch = useDebounce(inputValue, 300);
	const { data: addresses, isLoading } =
		trpc.address.getSimilarAddresses.useQuery(
			{ address: debouncedSearch },
			{
				enabled: debouncedSearch.length >= 3,
			},
		);

	const handleInputChange = useCallback(
		(search: string) => {
			setInputValue(search);
			setValue(name, search as PathValue<T, Path<T>>);
		},
		[name, setValue],
	);

	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem className={cn("flex w-full flex-col")}>
					<FormLabel className="text-2xl font-medium text-primary">
						{label} {required && <span className="text-red-500">*</span>}
					</FormLabel>
					<Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
						<PopoverTrigger asChild>
							<FormControl>
								<Button
									disabled={disabled}
									className={cn(
										"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-2xl border " +
											"border-gray-300 bg-white p-8 font-normal text-black hover:bg-gray-100 disabled:opacity-50",
										!field.value && "text-muted-foreground",
										disabled && "cursor-not-allowed opacity-50",
									)}
									onClick={() => {
										setIsPopoverOpen((prev) => !prev);
									}}
								>
									{field.value || placeholder}
								</Button>
							</FormControl>
						</PopoverTrigger>
						<PopoverContent
							className="w-[100%] p-0 lg:min-w-[450px]"
							align="start"
						>
							<Command
								className={cn(
									"w-full justify-between overflow-hidden overflow-ellipsis whitespace-nowrap rounded-2xl border " +
										"border-gray-300  bg-white p-3 font-normal text-black disabled:opacity-50",
									!field.value && "text-muted-foreground",
									disabled && "cursor-not-allowed opacity-50",
								)}
							>
								<CommandInput
									placeholder={placeholder}
									onValueChange={handleInputChange}
									role="combobox"
									className="border-b-0 "
									value={field.value || ""}
								/>
								<CommandList>
									<CommandEmpty>
										{isLoading && inputValue.length >= 3 ? (
											<div className="flex items-center justify-center p-4">
												<Spinner className=" text-primary" />
											</div>
										) : inputValue.length < 3 ? (
											"Veuillez saisir au moins 3 caractères."
										) : (
											"Aucune adresse trouvée."
										)}
									</CommandEmpty>
									<CommandGroup>
										{addresses?.features.map((option, index) => (
											<CommandItem
												value={option.mainAddress}
												key={index}
												onSelect={() => {
													setValue(
														name,
														option.mainAddress as PathValue<T, Path<T>>,
														{ shouldValidate: true },
													);
													setValue(
														cityProps.name,
														option.city as PathValue<T, Path<T>>,
														{ shouldValidate: true },
													);
													setValue(
														postalCodeProps.name,
														option.postalCode as PathValue<T, Path<T>>,
														{ shouldValidate: true },
													);
													if (countryProps) {
														setValue(
															countryProps.name,
															option.country as PathValue<T, Path<T>>,
															{ shouldValidate: true },
														);
													}
													setIsPopoverOpen(false);
												}}
												className="font-normal"
											>
												<Check
													className={cn(
														"mr-2 h-4 w-4",
														option.mainAddress === field.value
															? "opacity-100"
															: "opacity-0",
													)}
												/>
												{option.mainAddress}
											</CommandItem>
										))}
									</CommandGroup>
								</CommandList>
							</Command>
						</PopoverContent>
					</Popover>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
