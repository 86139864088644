import { GuaranteeRiskType, GuaranteeType } from "@prisma/client";
import { z } from "zod";

import {
	addressSchema,
	citySchema,
	civilitySchema,
	companyNameSchema,
	complementaryActivitiesTypesSchema,
	countrySchema,
	coverageLimitSchema,
	effectiveDateSchema,
	emailSchema,
	firstNameSchema,
	lastNameSchema,
	phoneNumberSchema,
	postalCodeSchema,
	principalActivityTypeSchema,
	sirenOrSiretSchema,
	turnoverSchema,
	worldWideTurnoverSchema,
} from "../primitives";
import { isCyberOptionIncluded, isGuaranteeIncluded } from "../utils";

export const CoverageSelectionSchema = z.object({
	principalActivityType: principalActivityTypeSchema,
	complementaryActivitiesTypes: complementaryActivitiesTypesSchema.optional(),
	turnover: turnoverSchema,
	desiredEffectiveDate: effectiveDateSchema,
});
export type CoverageSelectionDto = z.infer<typeof CoverageSelectionSchema>;
export const OptionalGuaranteesSelectionSchema = z
	.object({
		isWorldWide: z.boolean(),
		worldWideTurnover: worldWideTurnoverSchema,
		isRCE: z.boolean(),
		isCyber: z.boolean(),
		isCyberOption1: z.boolean(),
		isCyberOption2: z.boolean(),
		isCyberOption3: z.boolean(),
		isCyberOption4: z.boolean(),
		isTDMI: z.boolean(),
		isPJ: z.boolean().optional(),
	})
	.refine(
		(data) => {
			if (data.isWorldWide) {
				return (
					data.worldWideTurnover !== null &&
					data.worldWideTurnover !== undefined &&
					data.worldWideTurnover > 0
				);
			}
			return true;
		},
		{
			message:
				"Le chiffre d'affaires monde entier est requis lorsque l'option monde entier est sélectionné.",
			path: ["worldWideTurnover"],
		},
	);

export const SelectedGuaranteesSchema = z.array(
	z.object({
		type: z.nativeEnum(GuaranteeType),
		risksCovered: z.array(z.nativeEnum(GuaranteeRiskType)),
	}),
);
export type SelectedGuaranteesDto = z.infer<typeof SelectedGuaranteesSchema>;

export const transformSelectedGuaranteesToSchema = (
	selectedGuarantees: SelectedGuaranteesDto,
	worldWideTurnover?: number,
) => {
	const isWorldWide = isGuaranteeIncluded(
		GuaranteeType.WORLDWIDE,
		selectedGuarantees,
	);
	const worldWideTurnoverInput = isWorldWide ? worldWideTurnover : null;
	const isCyber = isGuaranteeIncluded(GuaranteeType.CYBER, selectedGuarantees);
	const isRCE = isGuaranteeIncluded(GuaranteeType.RCE, selectedGuarantees);
	const isCyberOption1 = isCyberOptionIncluded(
		GuaranteeRiskType.CYBER_BusinessInterruption,
		selectedGuarantees,
	);
	const isCyberOption2 = isCyberOptionIncluded(
		GuaranteeRiskType.CYBER_ThirdPartyDamage,
		selectedGuarantees,
	);
	const isCyberOption3 = isCyberOptionIncluded(
		GuaranteeRiskType.CYBER_ProfessionalActivityInterruption,
		selectedGuarantees,
	);
	const isCyberOption4 = isCyberOptionIncluded(
		GuaranteeRiskType.CYBER_FraudHacking,
		selectedGuarantees,
	);
	const isTDMI = isGuaranteeIncluded(GuaranteeType.TDMI, selectedGuarantees);
	return OptionalGuaranteesSelectionSchema.parse({
		isWorldWide: isWorldWide,
		worldWideTurnover: worldWideTurnoverInput,
		isRCE: isRCE,
		isCyber: isCyber,
		isCyberOption1: isCyberOption1,
		isCyberOption2: isCyberOption2,
		isCyberOption3: isCyberOption3,
		isCyberOption4: isCyberOption4,
		isTDMI: isTDMI,
		isPJ: true,
	});
};

export const BasicGuaranteeInputSchema = z.object({
	coverageLimit: coverageLimitSchema,
});
export type BasicGuaranteeInputDto = z.infer<typeof BasicGuaranteeInputSchema>;
export const legalRepresentiveSchema = z.object({
	civility: civilitySchema,
	email: emailSchema,
	lastName: lastNameSchema,
	firstName: firstNameSchema,
	phoneNumber: phoneNumberSchema,
});
export type LegalRepresentiveDto = z.infer<typeof legalRepresentiveSchema>;
export const SubscriberSchema = z.object({
	companyName: companyNameSchema,
	// SIREN or SIRET
	// Length must be 9 or 14
	sirenOrSiret: sirenOrSiretSchema,
	address: addressSchema,
	city: citySchema,
	postalCode: postalCodeSchema,
	country: countrySchema,
	phoneNumber: phoneNumberSchema,
	legalRepresentive: legalRepresentiveSchema,
});
export const CompanySchema = SubscriberSchema.omit({
	legalRepresentive: true,
});
export type CompanyDto = z.infer<typeof CompanySchema>;
export type SubscriberInputDto = z.infer<typeof SubscriberSchema>;
export const OptionalGuaranteesInputSelectionSchema = z
	.object({
		worldWideTurnover: worldWideTurnoverSchema,
		selectedGuarantees: SelectedGuaranteesSchema,
	})
	.refine(
		(data) => {
			if (data.selectedGuarantees.some((g) => g.type === "WORLDWIDE")) {
				return (
					data.worldWideTurnover !== null &&
					data.worldWideTurnover !== undefined &&
					data.worldWideTurnover > 0
				);
			}
			return true;
		},
		{
			message:
				"Le chiffre d'affaires monde entier est requis lorsque l'option monde entier est sélectionné.",
			path: ["worldWideTurnover"],
		},
	);
export type OptionalGuaranteesInputSelectionDto = z.infer<
	typeof OptionalGuaranteesInputSelectionSchema
>;
export const SubscriptionIdInputSchema = z.object({
	subscriptionId: z.string(),
});
