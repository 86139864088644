import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { cn } from "@/utils";

interface FraisInputProps<T extends FieldValues> extends UseControllerProps<T> {
	label: string;
	disabled?: boolean;
}

export function FraisInput<T extends FieldValues>({
	label,
	name,
	control,
	disabled,
}: FraisInputProps<T>) {
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<div className="border-b border-b-gray-400 py-4 ">
					<FormItem className="flex w-full items-center justify-between">
						<FormLabel
							className={cn("text-xl font-bold", {
								"text-black/40": disabled,
							})}
						>
							{label}
						</FormLabel>
						<FormControl>
							<Input
								{...field}
								className="w-4/12 rounded-2xl border-none bg-white p-6 text-xl font-medium"
								endAdornment={<p>€</p>}
								type="number"
								disabled={disabled}
							/>
						</FormControl>
					</FormItem>
					<FormMessage />
				</div>
			)}
		/>
	);
}
