import emailIcon from "@/assets/emailIcon.png";
import linkIcon from "@/assets/linkIcon.png";
import { Button } from "@/components/ui/button.tsx";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { cn, trpc } from "@/utils";
import { CheckIcon, Loader2, MailCheck } from "lucide-react";

export const SendQuoteAndLinkPage = () => {
	const subscriptionId = formStore((state) => state.subscriptionId);
	const navigate = useCustomFormNavigate();
	if (!subscriptionId) {
		navigate("CoverageChoice");
	}

	const sendDevisEmailToProspectMutation =
		trpc.mailing.sendQuoteEmailToSubscriber.useMutation({
			onError: () => {
				toast({
					title: "Erreur",
					description:
						"Une erreur s'est produite lors de l'envoi de l'email. Veuillez réessayer.",
					variant: "destructive",
				});
			},
		});
	const sendLinkEmailToProspectMutation =
		trpc.mailing.sendDebranchmentEmail.useMutation({
			onError: () => {
				toast({
					title: "Erreur",
					description:
						"Une erreur s'est produite lors de l'envoi de l'email. Veuillez réessayer.",
					variant: "destructive",
				});
			},
		});
	const handleSendDevisEmailToProspect = () => {
		if (sendDevisEmailToProspectMutation.isLoading) return;
		if (sendDevisEmailToProspectMutation.isSuccess) {
			toast({
				title: "Succès",
				description: "L'email est déjà envoyé.",
				variant: "success",
			});
			return;
		}
		sendDevisEmailToProspectMutation.mutate({
			subscriptionId: subscriptionId as string,
		});
	};
	const handleSendLinkEmailToProspect = () => {
		if (sendLinkEmailToProspectMutation.isLoading) return;
		if (sendLinkEmailToProspectMutation.isSuccess) {
			toast({
				title: "Succès",
				description: "L'email est déjà envoyé.",
				variant: "success",
			});
			return;
		}
		sendLinkEmailToProspectMutation.mutate({
			subscriptionId: subscriptionId as string,
		});
	};
	return (
		<div className="flex flex-col items-center justify-center gap-10 p-32">
			<div className="flex w-full max-w-[700px] flex-col items-center justify-center gap-6 text-center text-2xl font-bold text-primary">
				<p>Votre demande est prise en compte.</p>
				<p>
					Vous pouvez à présent transmettre le devis et le lien de souscription
					à votre client.
				</p>
			</div>
			<div className="flex items-center justify-center gap-6">
				<Button
					type="button"
					className="items-center justify-between gap-2 text-wrap bg-primary p-12 text-start text-2xl text-white"
					onClick={handleSendDevisEmailToProspect}
				>
					<span className="mr-2 flex h-12 w-12 items-center justify-center text-white">
						{sendDevisEmailToProspectMutation.isLoading ? (
							<Loader2 className="h-5 w-5 animate-spin" />
						) : sendDevisEmailToProspectMutation.isSuccess ? (
							<MailCheck />
						) : (
							<img src={emailIcon} alt="email" className="w-8" />
						)}
					</span>
					<span
						className={cn("w-full", {
							"opacity-80":
								sendDevisEmailToProspectMutation.isLoading ||
								sendDevisEmailToProspectMutation.isSuccess,
						})}
					>
						Cliquez ici pour envoyer le devis au prospect
					</span>
				</Button>
				<TooltipProvider>
					<Tooltip delayDuration={200}>
						<TooltipTrigger type="button">
							<Button
								type="button"
								onClick={handleSendLinkEmailToProspect}
								variant="secondary"
								className="
                                         disabled:bg-primary-100 flex
					items-center
					justify-between gap-2 text-wrap p-12 text-start
					text-2xl
					disabled:border
					disabled:border-gray-400
					disabled:opacity-60"
								disabled={!sendDevisEmailToProspectMutation.isSuccess}
							>
								<span className="mr-2 flex h-12  w-12 items-center justify-center text-white">
									{sendLinkEmailToProspectMutation.isLoading ? (
										<Loader2 className="h-5 w-5 animate-spin" />
									) : sendLinkEmailToProspectMutation.isSuccess ? (
										<MailCheck />
									) : (
										<img src={linkIcon} alt="link" className="w-7" />
									)}
								</span>
								<span
									className={cn({
										"opacity-80":
											sendLinkEmailToProspectMutation.isLoading ||
											sendLinkEmailToProspectMutation.isSuccess,
									})}
								>
									Envoyer un lien de souscription à votre client
								</span>
							</Button>
						</TooltipTrigger>
						<TooltipContent side="bottom">
							<p>
								L&apos;envoi du devis est obligatoire avant d&apos;envoyer le
								lien de souscription.
							</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
			<div className="flex w-full flex-col gap-6 ">
				{sendDevisEmailToProspectMutation.isSuccess && (
					<div className="max-w-md ">
						<div className="flex items-center space-x-4 rounded-lg bg-white p-4 shadow-sm">
							<div className="rounded bg-red-600 p-2">
								<CheckIcon className="h-6 w-6 text-white" />
							</div>
							<p className="font-bold text-primary">
								Votre devis a été envoyé par email avec succès
							</p>
						</div>
					</div>
				)}
				{sendLinkEmailToProspectMutation.isSuccess && (
					<div className="max-w-md">
						<div className="flex items-center space-x-4 rounded-lg bg-white p-4 shadow-sm">
							<div className="rounded bg-red-600 p-2">
								<CheckIcon className="h-6 w-6 text-white" />
							</div>
							<p className="font-bold text-primary">
								Votre lien de souscription a été envoyé par email avec succès
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
