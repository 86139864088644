import { useState } from "react";
import { SummarySection } from "@/components/SummarySection.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomFormNavigate } from "@/hooks/customNavigate.ts";
import { LegalRepInfoInputSection } from "@/pages/form/5/LegalRepInfoInputSection.tsx";
import { formStore } from "@/pages/form/stores/formStore.ts";
import { useAuthStore } from "@/stores/authStore.tsx";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type {
	BasicGuaranteeInputDto,
	CoverageSelectionDto,
	FeesInputDto,
	LegalRepresentiveDto,
	SubscriberInputDto,
} from "@repos/rcp-dtos";
import { legalRepresentiveSchema } from "@repos/rcp-dtos";

export const LegalRepInfoPage = () => {
	const [isCertified, setIsCertified] = useState(false);

	const navigate = useCustomFormNavigate();
	const subscriptionMutation = trpc.subscriptions.addSubscription.useMutation({
		onSuccess: (data) => {
			navigate("SendQuoteAndLink");
			formStoreData.setSubscriptionId(data);
		},
		onError: () => {
			toast({
				title: "Erreur",
				description:
					"Une erreur s'est produite lors de la soumission du formulaire. Veuillez réessayer.",
				variant: "destructive",
			});
		},
	});
	const formStoreData = formStore((state) => state);
	const partnershipId = useAuthStore((state) => state.partnershipId);

	const methods = useForm<LegalRepresentiveDto>({
		resolver: zodResolver(legalRepresentiveSchema),
		defaultValues: {
			...formStoreData.subscriber,
		},
	});

	const onSubmit = (data: LegalRepresentiveDto) => {
		formStoreData.setLegalRepresentive(data);
		subscriptionMutation.mutate({
			coverageSelection:
				formStoreData.coverageSelection as CoverageSelectionDto,
			fees: formStoreData.fees as FeesInputDto,
			basicGuarantee: formStoreData.basicGuarantee as BasicGuaranteeInputDto,
			optionalGuaranteesInputSelection:
				formStoreData.optionalGuaranteesInputSelection,
			subscriber: {
				...formStoreData.subscriber,
				legalRepresentive: data,
			} as SubscriberInputDto,
			hasConfirmedDeclarations: true,
			partnershipId: partnershipId as string,
		});
	};
	const handleReturnButton = () => {
		navigate("CompanyInfo");
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className={"relative grid h-full w-full grid-cols-12"}
			>
				<div className="col-span-7 flex h-full max-w-4xl flex-col justify-center  gap-6 p-16 pr-32">
					<LegalRepInfoInputSection />
					<section className=" flex w-full list-disc flex-col gap-6 space-y-1.5 rounded-2xl bg-white p-6 text-lg text-pale-black ">
						<div className="flex w-full items-center gap-4">
							<Checkbox
								id="certified"
								onCheckedChange={() => {
									setIsCertified(!isCertified);
								}}
							/>
							<label
								htmlFor="certified"
								className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
							>
								J&apos;ai pris connaissance des informations concernant la
								confidentialité et la protection des données dans la section
								Politique de Confidentialité et Conditions Générales.
							</label>
						</div>
					</section>
				</div>
				<SummarySection
					handleReturnButton={handleReturnButton}
					nextButtonProps={{
						disabled: !isCertified,
						children: "Souscrire",
						isLoading: subscriptionMutation.isLoading,
					}}
				/>
			</form>
		</FormProvider>
	);
};
