import type { FieldValues, UseControllerProps } from "react-hook-form";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";
import { FaFemale, FaMale } from "react-icons/fa";

import type { CivilityDto } from "@repos/rcp-dtos/src/primitives";

type GenderOptionsType = {
	title: string;
	icon: React.ReactNode;
	value: CivilityDto;
}[];

interface CivilityInputProps<T extends FieldValues>
	extends UseControllerProps<T> {
	label: string;
	required?: boolean;
}

const genderOptions: GenderOptionsType = [
	{ title: "Homme", icon: <FaMale />, value: "MR" },
	{ title: "Femme", icon: <FaFemale />, value: "MRS" },
];

export default function CivilityInput<T extends FieldValues>({
	name,
	label,
	required,
}: CivilityInputProps<T>) {
	const { control } = useFormContext<T>();
	return (
		<FormField
			control={control}
			name={name}
			render={({ field }) => (
				<FormItem className="flex w-full flex-col">
					<FormLabel className="text-2xl font-medium text-primary">
						{label} {required && <span className="text-red-500">*</span>}
					</FormLabel>
					<FormControl>
						<div className="flex w-full items-center justify-center gap-6">
							{genderOptions.map((item) => (
								<div
									key={item.value}
									onClick={() => field.onChange(item.value)}
									className={cn(
										"wf-1/2 flex w-full cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 bg-primary/10 p-6 text-primary",
										field.value === item.value
											? "border border-primary"
											: " border-transparent",
									)}
								>
									{item.icon}
									<Label className="mr-4 bg-transparent ">{item.title}</Label>
								</div>
							))}
						</div>
					</FormControl>
					<FormMessage className="ml-1 text-xs font-normal" />
				</FormItem>
			)}
		/>
	);
}
