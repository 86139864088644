import type { ReactNode } from "react";
import { Suspense, useState } from "react";
import { trpc } from "@/utils";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { httpBatchLink } from "@trpc/client";

import { transformer } from "@repos/trpc-utils";

type AppProviderProps = {
	children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: { refetchOnWindowFocus: false, cacheTime: 0 },
				},
			}),
	);

	const [trpcClient] = useState(() =>
		trpc.createClient({
			links: [
				httpBatchLink({
					url: `${import.meta.env.VITE_PUBLIC_RCP_API_URL}/api/trpc`,
				}),
			],
			transformer: transformer,
		}),
	);

	return (
		<Suspense
			fallback={
				<div className="flex h-screen w-screen items-center justify-center">
					loading ...
				</div>
			}
		>
			<Sentry.ErrorBoundary fallback={<div>Error</div>}>
				<trpc.Provider client={trpcClient} queryClient={queryClient}>
					<QueryClientProvider client={queryClient}>
						{/* eslint-disable-next-line turbo/no-undeclared-env-vars */}
						{import.meta.env.MODE === "development" && <ReactQueryDevtools />}
						{children}
					</QueryClientProvider>
				</trpc.Provider>
			</Sentry.ErrorBoundary>
		</Suspense>
	);
};
